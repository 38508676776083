import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  CREATED_AT,
  INCOMING,
  ORDER_STATUS_ORDERING_OPTIONS,
  TESTID_ORDERS_CREATOR_MODAL
} from 'constants/index';

import Modal from 'components/common/modal';
import ShortForm from 'components/orders-view/forms/short';

import {
  createOrderStatus,
  fetchOrderStatus,
  clearOrderStatusesContactsEntries,
  setOrderStatusesFilterKind,
  setOrderStatusesOrdering
} from 'store/order-statuses';

import useRoutesService from 'services/routes';

import styles from './creator.module.scss';

const OrderCreatorModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation('Contacts');
  const dispatch = useDispatch();
  const routes = useRoutesService();
  const { contactId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isHiring, setIsHiring] = useState(false);

  const { creatableCallback, withShowDetails } = data;

  const onSubmit = useCallback(
    async ({ contractor, ...values }) => {
      try {
        setIsLoading(true);

        const contractors = Array.isArray(contractor)
          ? contractor
          : [contractor];

        const hasOnlyOneContractor = contractors.length === 1;

        const results = await Promise.all(
          contractors.map(c =>
            dispatch(
              createOrderStatus({ values: { ...values, contractor: c } })
            )
          )
        );

        if (creatableCallback) {
          const orderStatus = await dispatch(
            fetchOrderStatus({ id: results[0].identifier })
          );
          creatableCallback(orderStatus);
        }

        if (withShowDetails && hasOnlyOneContractor) {
          routes.toNewOrderStatus({
            contactId: contractors[0].id || contactId,
            orderStatusId: results[0].identifier
          });
        }

        if (!hasOnlyOneContractor) {
          routes.toOrderStatuses();

          dispatch(setOrderStatusesFilterKind(INCOMING));

          const createdAtOrdering = ORDER_STATUS_ORDERING_OPTIONS.find(
            a => a.key === CREATED_AT
          );
          dispatch(
            setOrderStatusesOrdering({
              ...createdAtOrdering,
              key: `-${createdAtOrdering.key}`
            })
          );
        }

        dispatch(clearOrderStatusesContactsEntries());

        onClose();
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactId]
  );

  const handleIsHiringChange = hiringStatus => {
    setIsHiring(hiringStatus);
  };

  return (
    <Modal
      title={t('AddContactToFunnel')}
      visible={visible}
      onClose={onClose}
      destroyOnClose
      centered
      contentClassName={styles.root}
      width={isHiring ? 744 : 508}
      dataTestId={TESTID_ORDERS_CREATOR_MODAL}
    >
      <ShortForm
        isCreate
        withContractor={data.withContractor}
        isLoading={isLoading}
        defaultKind={data.defaultKind}
        defaultValues={data.defaultValues}
        onSubmit={onSubmit}
        onIsHiringChange={handleIsHiringChange}
      />
    </Modal>
  );
};

OrderCreatorModal.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.shape({
    creatableCallback: () => {},
    withShowDetails: PropTypes.bool,
    withContractor: PropTypes.bool,
    defaultKind: PropTypes.string,
    defaultValues: PropTypes.object
  }),
  onClose: PropTypes.func.isRequired
};

OrderCreatorModal.defaultProps = {
  visible: false,
  data: {
    withShowDetails: true
  }
};

export default OrderCreatorModal;
